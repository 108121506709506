.lp_survey_view_container {
  .lp_pages_area {
    .lp_radiobutton_wrapper {
      input[type='radio'] {
        display: none;
      }
    }
  }
}

.device-mobile {
  #careers_chat {
    font-size: 14px;
    font-family: $font--futura-book;
  }
}

.lp_select_field {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
  text-transform: capitalize;
}

.opc__cart,
.opc__signin,
.opc__wp_samples {
  .checkout__content {
    .cart-items__item {
      &--total {
        width: 30%;
        @include breakpoint($landscape-up) {
          width: 10%;
        }
      }
      &.sample {
        .cart-items__item--total {
          width: 16%;
          @include breakpoint($landscape-up) {
            width: 10%;
          }
        }
      }
    }
  }
}

.opc__shipping {
  .checkout__content {
    .city-state-zip {
      .form-item {
        &.postal-code {
          width: 28%;
          @include breakpoint($landscape-up) {
            width: 100%;
          }
        }
        &.city {
          width: 35%;
          @include breakpoint($landscape-up) {
            width: 50%;
          }
        }
      }
    }
  }
}

.page-wrapper {
  .page-header__nav,
  .page-header.alt-nav-mob {
    .gnav-util--search-v2.gnav-util {
      &.active-util {
        .gnav-util__content__inner form.gnav-search {
          .gnav-search--main-content {
            .gnav-search__suggestions-wrapper {
              .product-grid__content {
                .product-grid__item {
                  .product-brief__img {
                    .product-grid__content {
                      .product-grid__item {
                        padding: 10px 10px;
                        .product-brief {
                          padding: 0;
                        }
                        .product-brief__img {
                          max-width: 100%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .gnav-util__content__inner {
        form.gnav-search input[type='search'] {
          @include breakpoint($landscape-up) {
            position: fixed;
            left: auto;
            @if not is_cr20_desktop_nav_refresh() {
              @if is_2020_nav_redesign() {
                top: 73px;
                margin: 0 -5px 0 30px;
              } @else {
                top: 60px;
                margin-left: 2%;
              }
            }
          }
        }
      }
    }
  }
  .viewcart {
    margin-top: 58px;
    @include breakpoint($large-up) {
      margin-top: 0;
    }
    .checkout-header {
      &__title {
        &__count {
          display: inline-block;
          @include breakpoint($medium-up) {
            display: none;
          }
        }
      }
    }
    .checkout__panel--header {
      .pc-hidden {
        @include breakpoint($medium-up) {
          display: none;
        }
      }
    }
    .promo-bca-panel {
      @include breakpoint($medium-up) {
        padding: 1em;
      }
      .checkout__panel {
        &--header,
        &--content {
          padding: 0;
        }
        &--heading {
          margin-bottom: 0;
          display: block;
        }
      }
      &__description,
      &__logo {
        padding-top: 1em;
        font-size: 15px;
      }
    }
  }
}

@include breakpoint($medium-up) {
  .utility-nav-active {
    .page-header__nav {
      .gnav-util--search-v2.gnav-util.active-util {
        .gnav-util__content__inner form.gnav-search {
          display: grid;
          .gnav-search--main-content {
            .gnav-search__suggestions-wrapper {
              border-right: 0;
              display: block;
            }
            .gnav-search__content {
              border-left: 1px solid $color-light-gray;
              display: block;
            }
          }
        }
      }
    }
  }
}
