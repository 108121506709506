#cookie_popover_container {
  position: fixed;
  bottom: 0;
  border: $color-white;
  background-color: $color-black;
  color: $color-white;
  display: none;
  padding: 22px 30px 22px 22px;
  width: 100%;
  border-top: solid 1px $color-light-gray;
  z-index: 10000;
  /* @include breakpoint($small-up) {
    width: 295px;
    height: 150px;
    right: 10px;
    bottom: 10px;
    border: solid 1px $color-light-gray;
  } */

  a {
    color: $color-white;
    text-transform: none;
  }
  .cookie_legal-close_box {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
}
