.spp__container {
  margin-top: 25px;
}

.product-grid-wrapper {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-compact {
        .pr-snippet-stars-reco-stars {
          @include breakpoint($medium-down) {
            float: right;
          }
        }
      }
    }
  }
}
