.footer-links {
  &__choosers {
    @include breakpoint($landscape-up) {
      width: 28%;
      select.selectbox--new {
        max-width: 125px;
      }
    }
    @include breakpoint($largest-up) {
      select.selectbox--new {
        max-width: 200px;
      }
    }
  }
  &__tertiary-nav {
    @include breakpoint($landscape-up) {
      width: 46%;
    }
    @include breakpoint($largest-up) {
      width: 50%;
    }
  }
  &__copyright {
    @include breakpoint($landscape-up) {
      width: 26%;
    }
    @include breakpoint($largest-up) {
      width: 22%;
    }
  }
  .language-select__item--02 {
    padding-left: 5px;
  }
  &__item {
    @include breakpoint($landscape-up) {
      padding: 0 10px;
    }
    @include breakpoint($largest-up) {
      padding: 0 15px;
    }
  }
}

.section-sign-up {
  .site-email-signup {
    &--promotion {
      max-width: 85%;
      @include breakpoint($medium-up) {
        max-width: 95%;
      }
      @include breakpoint($landscape-up) {
        max-width: 69%;
      }
      .site-email-signup {
        &__fields {
          display: block;
          max-width: min-content;
          .label {
            margin-top: 20px;
            @include breakpoint($medium-up) {
              @include swap_direction(margin, 20px 10px 0 20px);
            }
          }
        }
        &__submit {
          margin-top: 0;
        }
      }
    }
  }
  #cboxOverlay {
    &.promotion-signup-thanks {
      background-color: $color-white;
    }
  }
  .popup-content {
    height: 220px !important;
    width: 315px !important;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    @include breakpoint($medium-up) {
      top: 0;
    }
  }
}

.adpl {
  &.apdl-promotion {
    input[type='email'] + label {
      margin-#{$ldirection}: 0;
      @include breakpoint($medium-up) {
        margin-#{$ldirection}: 20px;
      }
    }
  }
}
