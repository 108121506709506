// These styles seem specific to CA - Shahruk Khan 10/8/2015
.site-email-signup__fields {
  span.label {
    font-size: 16px;
  }
  p {
    margin-top: 0;
    font-size: 14px;
  }
}

//Signin Page
.sign-in_content {
  padding-left: 0;
  .sign-in-page {
    padding: 60px 20px;
    @include breakpoint($small-down) {
      padding: 20px;
    }
    .return-user,
    .new-account {
      height: auto;
      position: relative;
      right: 0;
      top: 0;
      width: auto;
      margin: 0;
      text-align: left;
      padding: 0;
      a {
        text-decoration: none;
      }
      a:hover {
        text-decoration: underline;
      }
    }
    .form-item {
      margin: 0 0 20px;
      label,
      span {
        font-size: 16px;
      }
      width: 50%;
      @include breakpoint($small-down) {
        width: 100%;
      }
    }
    .account-main-content {
      border: medium none;
      padding-right: 0;
    }
    .account-page__toggle-link {
      font-size: 16px;
      text-align: left;
    }
    .new-account__item.form-item--full,
    .return-user__item.form-item--full {
      width: 100%;
    }
    h2.account-page__section--heading {
      font-family: $font--subheading;
      font-size: 30px;
      font-weight: normal;
      letter-spacing: 0.05em;
      line-height: 1;
      margin-bottom: 1rem;
      margin-top: 0;
      text-transform: uppercase;
      font-style: normal;
      color: $color-black;
      display: block;
    }
  }
  .sign-in_content_wrapper {
    border: none;
  }
}

.opc__cart {
  .checkout__content {
    .cart-items {
      &__item--total {
        width: 30%;
        @include breakpoint($medium-up) {
          width: 10%;
        }
      }
    }
  }
}

.opc__payment {
  .payment-container {
    .paypal-address {
      display: none;
    }
  }
}

//recommended section
.recommended-item {
  .recommended-item {
    &__product {
      &-image {
        &,
        &__image {
          @include breakpoint($medium-up) {
            max-height: 260px;
          }
        }
      }
      &-info {
        text-align: left;
        padding: 0;
        &__name {
          margin-top: 10px;
          font-family: $font--futura-demi;
          font-size: 15px;
        }
        &__subname {
          font-family: $font--futura-medium;
          font-size: 15px;
          text-transform: capitalize;
          padding-bottom: 10px;
          line-height: 18px;
        }
        &__price {
          font-family: $font--futura-book;
          font-size: 15px;
          margin: 15px 0;
        }
      }
    }
    &__cta {
      text-align: left;
    }
  }
}

.optanon-status-editable,
.optanon-status-on {
  input[type='checkbox'] {
    & ~ label {
      &:before {
        display: none;
      }
    }
  }
}

#cookie_popover_container {
  display: none !important;
}

.quiz-landing-page-formatter {
  .quiz-landing-page-formatter__results {
    .quiz-landing-page-formatter__body {
      .retake-quiz {
        width: 13%;
      }
    }
  }
}
