.optanon-show-settings-popup-wrapper {
  display: inline-block;
  .optanon-show-settings-button {
    .optanon-show-settings-left,
    .optanon-show-settings-middle,
    .optanon-show-settings-right {
      background: none !important;
      border: 0 !important;
      height: auto !important;
      width: auto !important;
    }
    .optanon-show-settings-middle {
      float: none;
      padding-top: 0;
      .optanon-show-settings {
        font-size: 15px;
        font-family: $font--futura-book;
        color: $color-drak-black !important;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 1.42;
        text-decoration: underline;
        &:hover {
          text-decoration: underline !important;
        }
      }
    }
    .optanon-show-settings-right {
      float: none;
    }
  }
}

.optanon-status-editable,
.optanon-status-on {
  input[type='checkbox'] {
    & ~ label {
      &:before {
        display: none;
      }
    }
  }
}

#onetrust-consent-sdk {
  #onetrust-banner-sdk {
    &.otFlat {
      #onetrust-group-container {
        width: 100%;
        #onetrust-policy {
          margin-left: 1em;
          #onetrust-policy-text {
            margin-bottom: 0;
            color: $color-white;
            margin-top: 10px;
          }
        }
      }
      #onetrust-button-group-parent {
        width: 95%;
        text-align: right;
        #onetrust-button-group {
          button {
            background: transparent;
            border: none;
            text-decoration: underline;
            width: auto;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
      #onetrust-close-btn-container {
        display: block;
        .onetrust-close-btn-handler {
          top: 78%;
          left: 90%;
          @include breakpoint($medium-up) {
            top: 70%;
            left: 96%;
          }
        }
      }
      #onetrust-close-btn-container-mobile {
        display: none !important;
      }
    }
  }
  .fade-in {
    background-image: none;
  }
}

#ot-sdk-btn {
  &.ot-sdk-show-settings {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    font-size: 15px !important;
    color: $color-drak-black !important;
    &:hover {
      text-decoration: underline;
    }
  }
}

#onetrust-pc-sdk {
  &.ot-sdk-container {
    .pc-header {
      .pc-logo-container {
        .pc-logo {
          width: auto;
          height: auto;
        }
      }
      .pc-title-container {
        width: 100%;
        @include breakpoint($medium-up) {
          width: calc(100% - 190px);
        }
        .pc-close-button {
          &:focus {
            outline: 0;
          }
        }
        #pc-title {
          font-size: 1.4em;
          &:before,
          &:after {
            display: none;
          }
        }
      }
    }
  }
}

#onetrust-consent-sdk {
  #onetrust-banner-sdk {
    &.otFloatingRoundedCorner {
      max-width: 100%;
      width: 100%;
      left: 0;
      bottom: 0 !important;
      background-color: $color-drak-black !important;
      opacity: 0.8;
      #onetrust-group-container {
        width: 100%;
        @include breakpoint($medium-up) {
          width: 85%;
        }
        #onetrust-policy {
          #onetrust-policy-text {
            color: $color-white;
          }
        }
      }
      #onetrust-button-group-parent {
        width: 100%;
        padding: 0;
        @include breakpoint($medium-up) {
          width: 15%;
        }
        #onetrust-button-group {
          button {
            background: transparent;
            color: $color-white;
            text-align: right;
            padding: 0 15% 0 0;
            @include breakpoint($medium-up) {
              padding-top: 14%;
              padding-right: 0;
              width: auto;
            }
          }
        }
      }
      #onetrust-close-btn-container {
        position: absolute;
        margin: 0;
        top: 10px;
        #{$rdirection}: 10px;
      }
      #onetrust-button-group {
        display: inline;
      }
      .ot-close-icon {
        width: 18px;
        height: 18px;
      }
    }
  }
}

#onetrust-button-group {
  button {
    box-shadow: none;
    border: none;
    border-radius: 0;
    &:hover {
      opacity: 1;
      background-color: $color-green;
    }
  }
}
