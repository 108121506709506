.elc-order-tracking-wrapper {
  .elc-order-tracking {
    &__content {
      .elc-order-tracking-see-delivery-results {
        @include content-block-btn-secondary;
        margin-top: 15px;
      }
    }
  }
}
