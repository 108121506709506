.signin-signup-update {
  .checkout {
    margin-top: 13px;
    &__header {
      border-bottom: 0;
      @include breakpoint($medium-up) {
        padding: 0;
      }
      .checkout--heading {
        display: none;
      }
    }
    &__content {
      border-#{$rdirection}: 0;
      float: none;
      width: 100%;
      #shipping-panel,
      #payment-panel,
      #sign-in-paypal-panel,
      #review-panel,
      #guarantee-panel,
      .checkout-header {
        display: none;
      }
    }
    &__sidebar {
      border-#{$ldirection}: 0;
      float: none;
      margin-#{$ldirection}: 0;
      width: 100%;
      #viewcart-panel,
      .order-summary-panel,
      #offer-code-panel,
      #links-panel {
        display: none;
      }
    }
    .messages {
      padding: 0 16px;
      @include breakpoint($medium-up) {
        padding: 0 56px;
      }
    }
  }
  .sign-in_wrapper {
    padding: 45px 0;
    .account-page {
      margin-top: 21px;
      @include breakpoint($medium-up) {
        margin-top: 0;
      }
    }
  }
  .error_messages {
    padding: 0 16px 5px;
    text-align: center;
    @include breakpoint($medium-up) {
      padding: 0 56px 5px;
    }
  }
  .sign-in-page {
    .account-main-content {
      border: 1px solid $color-light-gray;
      max-width: 514px;
      &__wrapper {
        background: none;
        padding: 0;
        .return-user,
        .new-account {
          padding: 0;
        }
      }
      .return-user {
        .field.adaptive-placeholder-mode {
          & + label {
            &::before {
              content: attr(placeholder);
            }
          }
        }
      }
    }
    .account-page {
      &__section--heading {
        color: $color-black;
        display: block;
        font-size: 28px;
        font-style: normal;
        line-height: 1.15;
        margin-bottom: 0;
        padding: 18px 0 15px;
        text-align: center;
        text-transform: uppercase;
      }
      &__forgot-password {
        text-align: $ldirection;
        text-decoration: underline;
      }
      &__toggle-link,
      &__forgot-password {
        .link {
          text-decoration: underline;
          text-transform: none;
        }
      }
      &__toggle-link {
        font-size: 14px;
        letter-spacing: 0;
        margin-top: 11px;
      }
    }
    .return-user,
    .new-account {
      fieldset {
        border: 0;
        padding: 0 15px;
        @include breakpoint($medium-up) {
          padding: 0 56px;
        }
        .form-text {
          margin: 0;
          width: 100%;
          .field {
            border: 1px solid $color-gray;
            border-radius: 0;
            font-size: 16px;
            height: 54px;
            &.error {
              border-color: $color-red;
            }
          }
        }
        .form-button {
          max-width: 100%;
          margin: 0;
          .button {
            border-color: $color-black;
            font-size: 18px;
            height: 48px;
            line-height: 1.35;
            margin: 0;
            width: 100%;
          }
        }
        .terms_privacy {
          text-transform: none;
        }
      }
    }
    .new-account {
      .username-field {
        margin: 0;
        &.clearfix {
          &::after {
            content: '';
          }
        }
        .form-text {
          margin-#{$rdirection}: 0;
          overflow: visible;
          @include breakpoint($medium-up) {
            float: $ldirection;
            margin: 0;
            padding-#{$rdirection}: 8px;
            width: 50%;
          }
          &:last-child {
            padding-#{$rdirection}: 0;
            @include breakpoint($medium-up) {
              padding-#{$ldirection}: 8px;
            }
          }
        }
      }
      .form-item {
        margin: 0;
        b {
          font-weight: normal;
        }
        &.email-signup,
        &.privacy-policy-terms,
        &.loyalty-terms {
          .field {
            & + label {
              font-size: 14px;
              height: auto;
              line-height: 1.3;
            }
          }
        }
      }
      input.signin_password {
        border: 1px solid $color-gray;
        border-radius: 0;
      }
      .register-disclaimer {
        display: inline-block;
        font-size: 14px;
        margin: 6px 0;
      }
    }
    &__link {
      border-bottom: 4px solid $color-light-gray;
      color: $transparent--black;
      cursor: pointer;
      float: $ldirection;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 0.5px;
      line-height: 1;
      margin: 0;
      padding: 15px 0 17px;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      width: 50%;
      &.active {
        border-bottom-color: $color-black;
        color: $color-black;
      }
    }
  }
  .form-item {
    position: relative;
    .password-eye-icon {
      #{$rdirection}: 15px;
      cursor: pointer;
      position: absolute;
      top: 16px;
      .show-password-icon,
      .hide-password-icon {
        background: url('/media/images/checkout/show_password_icon.svg') no-repeat 0 2px;
        display: block;
        height: 20px;
        width: 22px;
      }
      .hide-password-icon {
        background: url('/media/images/checkout/hide_password_icon.svg') no-repeat;
      }
    }
  }
  #sign-in-panel,
  .sign-in-page {
    margin: 0 auto;
    &.checkout__panel {
      border: 0;
      margin-bottom: 50px;
      padding: 0 0 40px;
      @include breakpoint($medium-up) {
        padding-top: 32px;
      }
    }
    .checkout__panel {
      &--heading {
        display: block;
        font-size: 28px;
        letter-spacing: 0.5px;
        line-height: 1.2;
        margin: 0;
        padding: 18px 0;
        text-align: center;
      }
    }
    .signin-wrapper {
      border: 1px solid $color-light-gray;
      margin: 0 auto;
      max-width: 514px;
      padding: 0;
    }
    .social-login {
      margin: 0 0 42px;
      padding-top: 0;
    }
  }
  #sign-in-panel {
    #new-account,
    #return-user {
      float: none;
      min-height: auto;
      padding-bottom: 0;
      width: 100%;
    }
  }
  #return-user,
  #new-account {
    .checkout__panel {
      &--content {
        @include breakpoint($medium-up) {
          padding: 0 56px;
        }
        fieldset {
          .email_promotions {
            border: 0;
            .label-content {
              font-size: 14px;
            }
          }
          .field {
            border-color: $color-gray;
            border-radius: 0;
            font-size: 16px;
            height: 54px;
            padding: 14px 16px;
            &.error {
              border-color: $color-red;
            }
          }
          .form-item.submit {
            padding-#{$rdirection}: 0;
            position: static;
            width: 100%;
          }
        }
      }
    }
  }
  #new-account {
    .pc_email_promo_container {
      .link {
        display: none;
      }
    }
  }
  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
    .form-item {
      &--full {
        padding: 0 0 20px;
        .password {
          position: relative;
        }
        .forgot-pw-note {
          display: block;
          .link {
            font-size: 16px;
            line-height: 1.25;
            text-decoration: underline;
            text-transform: none;
          }
        }
      }
      &.email-promotions {
        border: 0;
        padding: 0;
      }
    }
    .button {
      font-size: 18px;
      height: 48px;
      line-height: 1.6;
      padding: 10px 14px;
    }
  }
  .fb-login-button {
    height: 48px;
    opacity: 0;
    width: 100%;
  }
  .custom-fb {
    display: block;
    position: relative;
    width: 100%;
    &__content {
      background: $color-facebook-blue;
      border-radius: 4px;
      cursor: pointer;
      height: 48px;
      line-height: 2.8;
      margin: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    &__icon {
      border-#{$rdirection}: 1px solid $color-danube-blue;
      float: $ldirection;
      padding: 12px;
      width: 47px;
    }
    &__desc {
      color: $color-white;
      font-size: 18px;
      letter-spacing: 0;
      padding: 12px;
      width: 100%;
    }
  }
  .adpl {
    .field,
    .user-password,
    .signin_password {
      color: $transparent--black;
      font-size: 16px;
      height: 54px;
      line-height: 1.5;
      & + label {
        color: $transparent--black;
        font-size: 16px;
        height: 48px;
        line-height: 3;
        margin-#{$ldirection}: 0;
      }
      &.adaptive-label-mode {
        color: $color-black;
        & + label {
          &::before {
            transform: translateY(-30px) scale(0.8, 0.8);
          }
        }
      }
    }
    label.label {
      color: $color-black;
    }
    input[type='submit'] {
      &:valid {
        border-color: $color-black;
      }
    }
    #return-user,
    #new-account {
      a {
        text-decoration: underline;
      }
    }
    .signin-wrapper {
      #return-user,
      #new-account {
        .field.adaptive-placeholder-mode {
          & + label {
            &::before {
              content: attr(placeholder);
            }
          }
        }
      }
    }
  }
  .social-login {
    font-size: 14px;
    margin-top: 0;
    padding: 0 16px;
    position: relative;
    @include breakpoint($medium-up) {
      padding: 0 56px;
    }
    &__container {
      padding-top: 15px;
    }
    &__divider {
      margin-bottom: 20px;
      &::before {
        background: $color-light-gray;
      }
      .inner {
        color: $color-black;
      }
    }
    &__email-opt-in {
      margin: 5px 0 0;
      span {
        color: $color-black;
        font-size: 14px;
      }
      .field + label {
        font-size: 14px;
        height: auto;
        line-height: 1.3;
      }
    }
    &__terms {
      padding-#{$ldirection}: 0;
      p {
        font-size: 14px;
      }
    }
    &__email-opt-in,
    &__terms {
      a {
        text-decoration: underline;
        text-transform: none;
      }
    }
  }
  .non-loyalty-user {
    font-family: $font--futura-medium;
    font-size: 16px;
    letter-spacing: 0.29px;
    line-height: 1;
    margin-bottom: 12px;
  }
  #offer-code-panel,
  #links-panel {
    display: none;
  }
  &.en_US {
    .sign-in-page,
    .checkout-single-page {
      .new-account {
        .form-item {
          &.privacy-policy-terms {
            display: none;
          }
        }
      }
      .social-login__email-opt-in {
        display: none;
      }
    }
  }
  &.en_US.sign-in-sign-up-page-new-user,
  &.en_US.checkout-sign-in-sign-up-page-guest-user {
    .sign-in-page,
    .checkout-single-page {
      .social-login__terms {
        display: none;
      }
    }
  }
}

.custom-fb {
  display: none;
}
